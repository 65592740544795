/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BidirectionalActionEnabledItem } from './BidirectionalActionEnabledItem';
import {
    BidirectionalActionEnabledItemFromJSON,
    BidirectionalActionEnabledItemFromJSONTyped,
    BidirectionalActionEnabledItemToJSON,
} from './BidirectionalActionEnabledItem';

/**
 * 
 * @export
 * @interface FacilityIntegration
 */
export interface FacilityIntegration {
    /**
     * 
     * @type {string}
     * @memberof FacilityIntegration
     */
    name: FacilityIntegrationNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityIntegration
     */
    is_referral_platform: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacilityIntegration
     */
    status: FacilityIntegrationStatusEnum;
    /**
     * 
     * @type {Array<BidirectionalActionEnabledItem>}
     * @memberof FacilityIntegration
     */
    bidirectional_actions_enabled: Array<BidirectionalActionEnabledItem>;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof FacilityIntegration
     */
    attributes?: { [key: string]: any; };
}


/**
 * @export
 */
export const FacilityIntegrationNameEnum = {
    Aida: 'aida',
    Aidin: 'aidin',
    Ensocare: 'ensocare',
    EnsocareVa: 'ensocare_va',
    EpicCareLink: 'epic_care_link',
    EpicCareLinkAllinaHealth: 'epic_care_link_allina_health',
    EpicCareLinkBsaHospital: 'epic_care_link_bsa_hospital',
    EpicCareLinkDenverHealth: 'epic_care_link_denver_health',
    EpicCareLinkHartfordHealthcare: 'epic_care_link_hartford_healthcare',
    EpicCareLinkHealthPartners: 'epic_care_link_health_partners',
    EpicCareLinkHennepin: 'epic_care_link_hennepin',
    EpicCareLinkLegacyHealthyPlanet: 'epic_care_link_legacy_healthy_planet',
    EpicCareLinkMayoClinic: 'epic_care_link_mayo_clinic',
    EpicCareLinkMercy: 'epic_care_link_mercy',
    EpicCareLinkNorthMemorialHealth: 'epic_care_link_north_memorial_health',
    EpicCareLinkPiedmont: 'epic_care_link_piedmont',
    EpicCareLinkProvidence: 'epic_care_link_providence',
    EpicCareLinkSsmHealth: 'epic_care_link_ssm_health',
    EpicCareLinkUcHealth: 'epic_care_link_uc_health',
    EpicCareLinkUnityPointHealth: 'epic_care_link_unity_point_health',
    EpicCareLinkUtHealthEastTexas: 'epic_care_link_ut_health_east_texas',
    EpicCareLinkWellstar: 'epic_care_link_wellstar',
    Fax: 'fax',
    ManualUpload: 'manual_upload',
    Pcc: 'pcc',
    WellskyIntake: 'wellsky_intake',
    WellskyReferralManagement: 'wellsky_referral_management',
    WellskyReferralManagementWebReferral: 'wellsky_referral_management_web_referral'
} as const;
export type FacilityIntegrationNameEnum = typeof FacilityIntegrationNameEnum[keyof typeof FacilityIntegrationNameEnum];

/**
 * @export
 */
export const FacilityIntegrationStatusEnum = {
    Complete: 'complete',
    Pending: 'pending',
    Disabled: 'disabled'
} as const;
export type FacilityIntegrationStatusEnum = typeof FacilityIntegrationStatusEnum[keyof typeof FacilityIntegrationStatusEnum];


/**
 * Check if a given object implements the FacilityIntegration interface.
 */
export function instanceOfFacilityIntegration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "is_referral_platform" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "bidirectional_actions_enabled" in value;

    return isInstance;
}

export function FacilityIntegrationFromJSON(json: any): FacilityIntegration {
    return FacilityIntegrationFromJSONTyped(json, false);
}

export function FacilityIntegrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilityIntegration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'is_referral_platform': json['is_referral_platform'],
        'status': json['status'],
        'bidirectional_actions_enabled': ((json['bidirectional_actions_enabled'] as Array<any>).map(BidirectionalActionEnabledItemFromJSON)),
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
    };
}

export function FacilityIntegrationToJSON(value?: FacilityIntegration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'is_referral_platform': value.is_referral_platform,
        'status': value.status,
        'bidirectional_actions_enabled': ((value.bidirectional_actions_enabled as Array<any>).map(BidirectionalActionEnabledItemToJSON)),
        'attributes': value.attributes,
    };
}

