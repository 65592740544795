/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdmissionStatus } from './AdmissionStatus';
import {
    AdmissionStatusFromJSON,
    AdmissionStatusFromJSONTyped,
    AdmissionStatusToJSON,
} from './AdmissionStatus';
import type { AnalysisResult } from './AnalysisResult';
import {
    AnalysisResultFromJSON,
    AnalysisResultFromJSONTyped,
    AnalysisResultToJSON,
} from './AnalysisResult';
import type { Document } from './Document';
import {
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
} from './Document';
import type { Facility } from './Facility';
import {
    FacilityFromJSON,
    FacilityFromJSONTyped,
    FacilityToJSON,
} from './Facility';
import type { LeadAdmissionStatusInfo } from './LeadAdmissionStatusInfo';
import {
    LeadAdmissionStatusInfoFromJSON,
    LeadAdmissionStatusInfoFromJSONTyped,
    LeadAdmissionStatusInfoToJSON,
} from './LeadAdmissionStatusInfo';
import type { LeadClaimedByFacility } from './LeadClaimedByFacility';
import {
    LeadClaimedByFacilityFromJSON,
    LeadClaimedByFacilityFromJSONTyped,
    LeadClaimedByFacilityToJSON,
} from './LeadClaimedByFacility';
import type { LeadScreenerLeadInfo } from './LeadScreenerLeadInfo';
import {
    LeadScreenerLeadInfoFromJSON,
    LeadScreenerLeadInfoFromJSONTyped,
    LeadScreenerLeadInfoToJSON,
} from './LeadScreenerLeadInfo';
import type { LeadScreenerLeadTagsInner } from './LeadScreenerLeadTagsInner';
import {
    LeadScreenerLeadTagsInnerFromJSON,
    LeadScreenerLeadTagsInnerFromJSONTyped,
    LeadScreenerLeadTagsInnerToJSON,
} from './LeadScreenerLeadTagsInner';
import type { LeadStatus } from './LeadStatus';
import {
    LeadStatusFromJSON,
    LeadStatusFromJSONTyped,
    LeadStatusToJSON,
} from './LeadStatus';
import type { PccPatient } from './PccPatient';
import {
    PccPatientFromJSON,
    PccPatientFromJSONTyped,
    PccPatientToJSON,
} from './PccPatient';
import type { ReferralBookInfo } from './ReferralBookInfo';
import {
    ReferralBookInfoFromJSON,
    ReferralBookInfoFromJSONTyped,
    ReferralBookInfoToJSON,
} from './ReferralBookInfo';
import type { ScreenerInsurance } from './ScreenerInsurance';
import {
    ScreenerInsuranceFromJSON,
    ScreenerInsuranceFromJSONTyped,
    ScreenerInsuranceToJSON,
} from './ScreenerInsurance';
import type { ScreenerSpecialCriteria } from './ScreenerSpecialCriteria';
import {
    ScreenerSpecialCriteriaFromJSON,
    ScreenerSpecialCriteriaFromJSONTyped,
    ScreenerSpecialCriteriaToJSON,
} from './ScreenerSpecialCriteria';
import type { ScreenerSuggestion } from './ScreenerSuggestion';
import {
    ScreenerSuggestionFromJSON,
    ScreenerSuggestionFromJSONTyped,
    ScreenerSuggestionToJSON,
} from './ScreenerSuggestion';
import type { SourceContact } from './SourceContact';
import {
    SourceContactFromJSON,
    SourceContactFromJSONTyped,
    SourceContactToJSON,
} from './SourceContact';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Lead
 */
export interface Lead {
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    source_id: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    source_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    lead_received_date: string;
    /**
     * 
     * @type {ScreenerSuggestion}
     * @memberof Lead
     */
    screener_suggestion?: ScreenerSuggestion;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    status_id?: string;
    /**
     * 
     * @type {LeadStatus}
     * @memberof Lead
     */
    status?: LeadStatus;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Lead
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Array<AnalysisResult>}
     * @memberof Lead
     */
    analysis_results?: Array<AnalysisResult>;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    report_s3_key?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    report_s3_access_url?: string;
    /**
     * 
     * @type {Array<ScreenerSpecialCriteria>}
     * @memberof Lead
     */
    special_criteria?: Array<ScreenerSpecialCriteria>;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    pcc_pending_patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    pcc_patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    comments_summary?: string;
    /**
     * 
     * @type {User}
     * @memberof Lead
     */
    pcc_push_by_user?: User;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    pcc_summary_report_pushed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    pcc_screener_results_pushed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    pcc_data_pulls_report_pushed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    pcc_s_offender_report_pushed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    pcc_medication_list_report_pushed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    pcc_clinic_package_pushed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    pcc_push_date?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    connector?: LeadConnectorEnum;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    connector_referral_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    dob?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    medicaid_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    medicare_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    ssn?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    source_contact_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    original_move_in_date?: string;
    /**
     * 
     * @type {SourceContact}
     * @memberof Lead
     */
    source_contact?: SourceContact;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    is_visual_references_ready?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    exclude_from_duplicate_check?: boolean;
    /**
     * 
     * @type {LeadClaimedByFacility}
     * @memberof Lead
     */
    claimed_by_facility?: LeadClaimedByFacility;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    is_cancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    duplicate_group_id?: string;
    /**
     * 
     * @type {Facility}
     * @memberof Lead
     */
    facility?: Facility;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    created_at?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    is_large_document_ai_used?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    medications_extraction_complete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    are_all_documents_analyzed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    has_insufficient_document_context?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    s_offender_records_stored?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    medicare_estimates_status?: LeadMedicareEstimatesStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    medicare_estimates_visual_refs_ready?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    source_comments?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    source_comments_received_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    declined_reason?: string;
    /**
     * 
     * @type {PccPatient}
     * @memberof Lead
     */
    pcc_patient?: PccPatient;
    /**
     * Deprecated. Use admission_status and admission_status_info instead.
     * @type {ReferralBookInfo}
     * @memberof Lead
     * @deprecated
     */
    referral_book_info?: ReferralBookInfo;
    /**
     * 
     * @type {AdmissionStatus}
     * @memberof Lead
     */
    admission_status?: AdmissionStatus;
    /**
     * 
     * @type {LeadAdmissionStatusInfo}
     * @memberof Lead
     */
    admission_status_info?: LeadAdmissionStatusInfo;
    /**
     * 
     * @type {Array<ScreenerInsurance>}
     * @memberof Lead
     */
    insurances?: Array<ScreenerInsurance>;
    /**
     * 
     * @type {LeadScreenerLeadInfo}
     * @memberof Lead
     */
    screener_lead_info?: LeadScreenerLeadInfo;
    /**
     * 
     * @type {Array<LeadScreenerLeadTagsInner>}
     * @memberof Lead
     */
    screener_lead_tags?: Array<LeadScreenerLeadTagsInner>;
}


/**
 * @export
 */
export const LeadConnectorEnum = {
    Aida: 'Aida',
    Aidin: 'Aidin',
    Ensocare: 'Ensocare',
    EnsocareVa: 'Ensocare VA',
    EpicCareLink: 'EpicCare Link',
    EpicCareLinkAllinaHealth: 'EpicCare Link Allina Health',
    EpicCareLinkBsaHospital: 'EpicCare Link BSA Hospital',
    EpicCareLinkDenverHealth: 'EpicCare Link Denver Health',
    EpicCareLinkHartfordHealthCare: 'EpicCare Link Hartford HealthCare',
    EpicCareLinkHealthPartners: 'EpicCare Link HealthPartners',
    EpicCareLinkHennepinCountyMedicalCenter: 'EpicCare Link Hennepin County Medical Center',
    EpicCareLinkLegacyHealthyPlanet: 'EpicCare Link Legacy Healthy Planet',
    EpicCareLinkMayoClinic: 'EpicCare Link Mayo Clinic',
    EpicCareLinkMercy: 'EpicCare Link Mercy',
    EpicCareLinkNorthMemorialHealth: 'EpicCare Link North Memorial Health',
    EpicCareLinkPiedmont: 'EpicCare Link Piedmont',
    EpicCareLinkProvidence: 'EpicCare Link Providence',
    EpicCareLinkSsmHealth: 'EpicCare Link SSM Health',
    EpicCareLinkUcHealth: 'EpicCare Link UCHealth',
    EpicCareLinkUnityPointHealth: 'EpicCare Link UnityPoint Health',
    EpicCareLinkUtHealthEastTexas: 'EpicCare Link UT Health East Texas',
    EpicCareLinkWellstar: 'EpicCare Link Wellstar',
    Fax: 'Fax',
    ManualUpload: 'Manual Upload',
    WellSkyIntake: 'WellSky Intake',
    WellSkyReferralManagement: 'WellSky Referral Management',
    WellSkyReferralManagementWebReferral: 'WellSky Referral Management Web Referral'
} as const;
export type LeadConnectorEnum = typeof LeadConnectorEnum[keyof typeof LeadConnectorEnum];

/**
 * @export
 */
export const LeadMedicareEstimatesStatusEnum = {
    NotActive: 'not_active',
    NotEnoughData: 'not_enough_data',
    InProgress: 'in_progress',
    Completed: 'completed',
    Error: 'error',
    Disabled: 'disabled',
    Scheduled: 'scheduled'
} as const;
export type LeadMedicareEstimatesStatusEnum = typeof LeadMedicareEstimatesStatusEnum[keyof typeof LeadMedicareEstimatesStatusEnum];


/**
 * Check if a given object implements the Lead interface.
 */
export function instanceOfLead(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "source_id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "lead_received_date" in value;

    return isInstance;
}

export function LeadFromJSON(json: any): Lead {
    return LeadFromJSONTyped(json, false);
}

export function LeadFromJSONTyped(json: any, ignoreDiscriminator: boolean): Lead {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'source_id': json['source_id'],
        'source_name': !exists(json, 'source_name') ? undefined : json['source_name'],
        'facility_id': json['facility_id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'lead_received_date': json['lead_received_date'],
        'screener_suggestion': !exists(json, 'screener_suggestion') ? undefined : ScreenerSuggestionFromJSON(json['screener_suggestion']),
        'status_id': !exists(json, 'status_id') ? undefined : json['status_id'],
        'status': !exists(json, 'status') ? undefined : LeadStatusFromJSON(json['status']),
        'documents': !exists(json, 'documents') ? undefined : ((json['documents'] as Array<any>).map(DocumentFromJSON)),
        'analysis_results': !exists(json, 'analysis_results') ? undefined : ((json['analysis_results'] as Array<any>).map(AnalysisResultFromJSON)),
        'report_s3_key': !exists(json, 'report_s3_key') ? undefined : json['report_s3_key'],
        'report_s3_access_url': !exists(json, 'report_s3_access_url') ? undefined : json['report_s3_access_url'],
        'special_criteria': !exists(json, 'special_criteria') ? undefined : ((json['special_criteria'] as Array<any>).map(ScreenerSpecialCriteriaFromJSON)),
        'pcc_pending_patient_id': !exists(json, 'pcc_pending_patient_id') ? undefined : json['pcc_pending_patient_id'],
        'pcc_patient_id': !exists(json, 'pcc_patient_id') ? undefined : json['pcc_patient_id'],
        'comments_summary': !exists(json, 'comments_summary') ? undefined : json['comments_summary'],
        'pcc_push_by_user': !exists(json, 'pcc_push_by_user') ? undefined : UserFromJSON(json['pcc_push_by_user']),
        'pcc_summary_report_pushed': !exists(json, 'pcc_summary_report_pushed') ? undefined : json['pcc_summary_report_pushed'],
        'pcc_screener_results_pushed': !exists(json, 'pcc_screener_results_pushed') ? undefined : json['pcc_screener_results_pushed'],
        'pcc_data_pulls_report_pushed': !exists(json, 'pcc_data_pulls_report_pushed') ? undefined : json['pcc_data_pulls_report_pushed'],
        'pcc_s_offender_report_pushed': !exists(json, 'pcc_s_offender_report_pushed') ? undefined : json['pcc_s_offender_report_pushed'],
        'pcc_medication_list_report_pushed': !exists(json, 'pcc_medication_list_report_pushed') ? undefined : json['pcc_medication_list_report_pushed'],
        'pcc_clinic_package_pushed': !exists(json, 'pcc_clinic_package_pushed') ? undefined : json['pcc_clinic_package_pushed'],
        'pcc_push_date': !exists(json, 'pcc_push_date') ? undefined : json['pcc_push_date'],
        'connector': !exists(json, 'connector') ? undefined : json['connector'],
        'connector_referral_id': !exists(json, 'connector_referral_id') ? undefined : json['connector_referral_id'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'medicaid_number': !exists(json, 'medicaid_number') ? undefined : json['medicaid_number'],
        'medicare_number': !exists(json, 'medicare_number') ? undefined : json['medicare_number'],
        'ssn': !exists(json, 'ssn') ? undefined : json['ssn'],
        'source_contact_id': !exists(json, 'source_contact_id') ? undefined : json['source_contact_id'],
        'original_move_in_date': !exists(json, 'original_move_in_date') ? undefined : json['original_move_in_date'],
        'source_contact': !exists(json, 'source_contact') ? undefined : SourceContactFromJSON(json['source_contact']),
        'is_visual_references_ready': !exists(json, 'is_visual_references_ready') ? undefined : json['is_visual_references_ready'],
        'exclude_from_duplicate_check': !exists(json, 'exclude_from_duplicate_check') ? undefined : json['exclude_from_duplicate_check'],
        'claimed_by_facility': !exists(json, 'claimed_by_facility') ? undefined : LeadClaimedByFacilityFromJSON(json['claimed_by_facility']),
        'is_cancelled': !exists(json, 'is_cancelled') ? undefined : json['is_cancelled'],
        'duplicate_group_id': !exists(json, 'duplicate_group_id') ? undefined : json['duplicate_group_id'],
        'facility': !exists(json, 'facility') ? undefined : FacilityFromJSON(json['facility']),
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'is_large_document_ai_used': !exists(json, 'is_large_document_ai_used') ? undefined : json['is_large_document_ai_used'],
        'medications_extraction_complete': !exists(json, 'medications_extraction_complete') ? undefined : json['medications_extraction_complete'],
        'are_all_documents_analyzed': !exists(json, 'are_all_documents_analyzed') ? undefined : json['are_all_documents_analyzed'],
        'has_insufficient_document_context': !exists(json, 'has_insufficient_document_context') ? undefined : json['has_insufficient_document_context'],
        's_offender_records_stored': !exists(json, 's_offender_records_stored') ? undefined : json['s_offender_records_stored'],
        'medicare_estimates_status': !exists(json, 'medicare_estimates_status') ? undefined : json['medicare_estimates_status'],
        'medicare_estimates_visual_refs_ready': !exists(json, 'medicare_estimates_visual_refs_ready') ? undefined : json['medicare_estimates_visual_refs_ready'],
        'source_comments': !exists(json, 'source_comments') ? undefined : json['source_comments'],
        'source_comments_received_at': !exists(json, 'source_comments_received_at') ? undefined : json['source_comments_received_at'],
        'declined_reason': !exists(json, 'declined_reason') ? undefined : json['declined_reason'],
        'pcc_patient': !exists(json, 'pcc_patient') ? undefined : PccPatientFromJSON(json['pcc_patient']),
        'referral_book_info': !exists(json, 'referral_book_info') ? undefined : ReferralBookInfoFromJSON(json['referral_book_info']),
        'admission_status': !exists(json, 'admission_status') ? undefined : AdmissionStatusFromJSON(json['admission_status']),
        'admission_status_info': !exists(json, 'admission_status_info') ? undefined : LeadAdmissionStatusInfoFromJSON(json['admission_status_info']),
        'insurances': !exists(json, 'insurances') ? undefined : ((json['insurances'] as Array<any>).map(ScreenerInsuranceFromJSON)),
        'screener_lead_info': !exists(json, 'screener_lead_info') ? undefined : LeadScreenerLeadInfoFromJSON(json['screener_lead_info']),
        'screener_lead_tags': !exists(json, 'screener_lead_tags') ? undefined : ((json['screener_lead_tags'] as Array<any>).map(LeadScreenerLeadTagsInnerFromJSON)),
    };
}

export function LeadToJSON(value?: Lead | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'source_id': value.source_id,
        'source_name': value.source_name,
        'facility_id': value.facility_id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'lead_received_date': value.lead_received_date,
        'screener_suggestion': ScreenerSuggestionToJSON(value.screener_suggestion),
        'status_id': value.status_id,
        'status': LeadStatusToJSON(value.status),
        'documents': value.documents === undefined ? undefined : ((value.documents as Array<any>).map(DocumentToJSON)),
        'analysis_results': value.analysis_results === undefined ? undefined : ((value.analysis_results as Array<any>).map(AnalysisResultToJSON)),
        'report_s3_key': value.report_s3_key,
        'report_s3_access_url': value.report_s3_access_url,
        'special_criteria': value.special_criteria === undefined ? undefined : ((value.special_criteria as Array<any>).map(ScreenerSpecialCriteriaToJSON)),
        'pcc_pending_patient_id': value.pcc_pending_patient_id,
        'pcc_patient_id': value.pcc_patient_id,
        'comments_summary': value.comments_summary,
        'pcc_push_by_user': UserToJSON(value.pcc_push_by_user),
        'pcc_summary_report_pushed': value.pcc_summary_report_pushed,
        'pcc_screener_results_pushed': value.pcc_screener_results_pushed,
        'pcc_data_pulls_report_pushed': value.pcc_data_pulls_report_pushed,
        'pcc_s_offender_report_pushed': value.pcc_s_offender_report_pushed,
        'pcc_medication_list_report_pushed': value.pcc_medication_list_report_pushed,
        'pcc_clinic_package_pushed': value.pcc_clinic_package_pushed,
        'pcc_push_date': value.pcc_push_date,
        'connector': value.connector,
        'connector_referral_id': value.connector_referral_id,
        'gender': value.gender,
        'dob': value.dob,
        'medicaid_number': value.medicaid_number,
        'medicare_number': value.medicare_number,
        'ssn': value.ssn,
        'source_contact_id': value.source_contact_id,
        'original_move_in_date': value.original_move_in_date,
        'source_contact': SourceContactToJSON(value.source_contact),
        'is_visual_references_ready': value.is_visual_references_ready,
        'exclude_from_duplicate_check': value.exclude_from_duplicate_check,
        'claimed_by_facility': LeadClaimedByFacilityToJSON(value.claimed_by_facility),
        'is_cancelled': value.is_cancelled,
        'duplicate_group_id': value.duplicate_group_id,
        'facility': FacilityToJSON(value.facility),
        'created_at': value.created_at,
        'is_large_document_ai_used': value.is_large_document_ai_used,
        'medications_extraction_complete': value.medications_extraction_complete,
        'are_all_documents_analyzed': value.are_all_documents_analyzed,
        'has_insufficient_document_context': value.has_insufficient_document_context,
        's_offender_records_stored': value.s_offender_records_stored,
        'medicare_estimates_status': value.medicare_estimates_status,
        'medicare_estimates_visual_refs_ready': value.medicare_estimates_visual_refs_ready,
        'source_comments': value.source_comments,
        'source_comments_received_at': value.source_comments_received_at,
        'declined_reason': value.declined_reason,
        'pcc_patient': PccPatientToJSON(value.pcc_patient),
        'referral_book_info': ReferralBookInfoToJSON(value.referral_book_info),
        'admission_status': AdmissionStatusToJSON(value.admission_status),
        'admission_status_info': LeadAdmissionStatusInfoToJSON(value.admission_status_info),
        'insurances': value.insurances === undefined ? undefined : ((value.insurances as Array<any>).map(ScreenerInsuranceToJSON)),
        'screener_lead_info': LeadScreenerLeadInfoToJSON(value.screener_lead_info),
        'screener_lead_tags': value.screener_lead_tags === undefined ? undefined : ((value.screener_lead_tags as Array<any>).map(LeadScreenerLeadTagsInnerToJSON)),
    };
}

