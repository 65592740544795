/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationCredentials
 */
export interface IntegrationCredentials {
    /**
     * 
     * @type {string}
     * @memberof IntegrationCredentials
     */
    readonly facility_id?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCredentials
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCredentials
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCredentials
     */
    integration_type: IntegrationCredentialsIntegrationTypeEnum;
}


/**
 * @export
 */
export const IntegrationCredentialsIntegrationTypeEnum = {
    Aida: 'aida',
    Aidin: 'aidin',
    Ensocare: 'ensocare',
    EnsocareVa: 'ensocare_va',
    EpicCareLink: 'epic_care_link',
    EpicCareLinkAllinaHealth: 'epic_care_link_allina_health',
    EpicCareLinkBsaHospital: 'epic_care_link_bsa_hospital',
    EpicCareLinkDenverHealth: 'epic_care_link_denver_health',
    EpicCareLinkHartfordHealthcare: 'epic_care_link_hartford_healthcare',
    EpicCareLinkHealthPartners: 'epic_care_link_health_partners',
    EpicCareLinkHennepin: 'epic_care_link_hennepin',
    EpicCareLinkLegacyHealthyPlanet: 'epic_care_link_legacy_healthy_planet',
    EpicCareLinkMayoClinic: 'epic_care_link_mayo_clinic',
    EpicCareLinkMercy: 'epic_care_link_mercy',
    EpicCareLinkNorthMemorialHealth: 'epic_care_link_north_memorial_health',
    EpicCareLinkPiedmont: 'epic_care_link_piedmont',
    EpicCareLinkProvidence: 'epic_care_link_providence',
    EpicCareLinkSsmHealth: 'epic_care_link_ssm_health',
    EpicCareLinkUcHealth: 'epic_care_link_uc_health',
    EpicCareLinkUnityPointHealth: 'epic_care_link_unity_point_health',
    EpicCareLinkUtHealthEastTexas: 'epic_care_link_ut_health_east_texas',
    EpicCareLinkWellstar: 'epic_care_link_wellstar',
    Fax: 'fax',
    ManualUpload: 'manual_upload',
    Pcc: 'pcc',
    WellskyIntake: 'wellsky_intake',
    WellskyReferralManagement: 'wellsky_referral_management',
    WellskyReferralManagementWebReferral: 'wellsky_referral_management_web_referral'
} as const;
export type IntegrationCredentialsIntegrationTypeEnum = typeof IntegrationCredentialsIntegrationTypeEnum[keyof typeof IntegrationCredentialsIntegrationTypeEnum];


/**
 * Check if a given object implements the IntegrationCredentials interface.
 */
export function instanceOfIntegrationCredentials(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "integration_type" in value;

    return isInstance;
}

export function IntegrationCredentialsFromJSON(json: any): IntegrationCredentials {
    return IntegrationCredentialsFromJSONTyped(json, false);
}

export function IntegrationCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_id': !exists(json, 'facility_id') ? undefined : json['facility_id'],
        'username': json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'integration_type': json['integration_type'],
    };
}

export function IntegrationCredentialsToJSON(value?: IntegrationCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
        'integration_type': value.integration_type,
    };
}

