import React, { ReactNode } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Chip, Stack, SxProps, Theme, Tooltip } from '@mui/material';
interface TagTypeChipProps {
  label: string;
  hideLabel?: boolean;
  tooltipTitle?: ReactNode;
  sx?: SxProps<Theme>;
}

export const TagTypeToChipMapping: {
  [key: number]: (props: TagTypeChipProps) => JSX.Element;
} = {
  1: (props: TagTypeChipProps) => (
    <Chip
      sx={{
        backgroundColor: '#00000014',
        ...props.sx
      }}
      label={
        <Stack gap={1} direction="row" alignItems="center">
          {!props.hideLabel && <Box>{props.label}</Box>}
          {props.tooltipTitle && (
            <TagTooltip tooltipTitle={props.tooltipTitle} />
          )}
        </Stack>
      }
    />
  ),
  2: (props: TagTypeChipProps) => (
    <Chip
      sx={{
        backgroundColor: '#E7EEF4',
        border: 'none',
        ...props.sx
      }}
      color="primary"
      variant="outlined"
      label={
        <Stack gap={1} direction="row" alignItems="center">
          {!props.hideLabel && <Box>{props.label}</Box>}
          {props.tooltipTitle && (
            <TagTooltip tooltipTitle={props.tooltipTitle} />
          )}
        </Stack>
      }
    />
  ),
  3: (props: TagTypeChipProps) => (
    <Chip
      sx={{
        backgroundColor: '#EAF2EA',
        border: 'none',
        color: '#569E59',
        ...props.sx
      }}
      label={
        <Stack gap={1} direction="row" alignItems="center">
          {!props.hideLabel && <Box>{props.label}</Box>}
          {props.tooltipTitle && (
            <TagTooltip tooltipTitle={props.tooltipTitle} />
          )}
        </Stack>
      }
    />
  ),
  4: (props: TagTypeChipProps) => (
    <Chip
      color="warning"
      sx={{
        backgroundColor: '#FFE9D4',
        border: 'none',
        ...props.sx
      }}
      variant="outlined"
      label={
        <Stack gap={1} direction="row" alignItems="center">
          {!props.hideLabel && <Box>{props.label}</Box>}
          {props.tooltipTitle && (
            <TagTooltip tooltipTitle={props.tooltipTitle} />
          )}
        </Stack>
      }
    />
  ),
  5: (props: TagTypeChipProps) => (
    <Chip
      sx={{
        backgroundColor: '#FFDAD4',
        border: 'none',
        color: '#BA1B1B',
        ...props.sx
      }}
      variant="outlined"
      label={
        <Stack gap={1} direction="row" alignItems="center">
          {!props.hideLabel && <Box>{props.label}</Box>}
          {props.tooltipTitle && (
            <TagTooltip tooltipTitle={props.tooltipTitle} />
          )}
        </Stack>
      }
    />
  ),
  6: (props: TagTypeChipProps) => (
    <Chip
      sx={{ ...props.sx }}
      color="primary"
      label={
        <Stack gap={1} direction="row" alignItems="center">
          {!props.hideLabel && <Box>{props.label}</Box>}
          {props.tooltipTitle && (
            <TagTooltip tooltipTitle={props.tooltipTitle} />
          )}
        </Stack>
      }
    />
  ),
  7: (props: TagTypeChipProps) => (
    <Chip
      sx={{ ...props.sx }}
      color="success"
      label={
        <Stack gap={1} direction="row" alignItems="center">
          {!props.hideLabel && <Box>{props.label}</Box>}
          {props.tooltipTitle && (
            <TagTooltip tooltipTitle={props.tooltipTitle} />
          )}
        </Stack>
      }
    />
  ),
  8: (props: TagTypeChipProps) => (
    <Chip
      sx={{ ...props.sx }}
      color="warning"
      label={
        <Stack gap={1} direction="row" alignItems="center">
          {!props.hideLabel && <Box>{props.label}</Box>}
          {props.tooltipTitle && (
            <TagTooltip tooltipTitle={props.tooltipTitle} />
          )}
        </Stack>
      }
    />
  ),
  9: (props: TagTypeChipProps) => (
    <Chip
      sx={{ ...props.sx }}
      color="error"
      label={
        <Stack gap={1} direction="row" alignItems="center">
          {!props.hideLabel && <Box>{props.label}</Box>}
          {props.tooltipTitle && (
            <TagTooltip tooltipTitle={props.tooltipTitle} />
          )}
        </Stack>
      }
    />
  )
};

export default function TagChip({
  type,
  label,
  hideLabel,
  tooltipTitle,
  sx
}: {
  type: number;
  label: string;
  hideLabel?: boolean;
  tooltipTitle?: ReactNode;
  sx?: SxProps<Theme>;
}) {
  const TagTypeChip = TagTypeToChipMapping[type];
  if (!TagTypeChip) {
    return null;
  }
  const sxProps = {
    width: 'auto',
    maxWidth: 'fit-content',
    height: '24px',
    fontWeight: 500,
    ...sx
  };
  return (
    <TagTypeChip
      {...{
        label,
        hideLabel,
        tooltipTitle,
        sx: sxProps
      }}
    />
  );
}

const TagTooltip = ({ tooltipTitle }: { tooltipTitle: ReactNode }) => {
  return (
    <Tooltip title={tooltipTitle}>
      <InfoOutlined
        sx={{
          fontSize: '20px'
        }}
      />
    </Tooltip>
  );
};
